import React from 'react';
import Layout from '../components/layout';
import EngagementBanner from '../components/reusable/EngagementBanner';
import ExploreCategories from '../components/reusable/ExploreCategories';
import NewsletterSignup from '../components/reusable/newsletterSignup';
import AuthorsList from '../components/reusable/authors-list';

export default () => {

  const bannerInfo = {
    layout: "wide-image",
    image: {
      src: "https://via.placeholder.com/1200x700.png/09f/fff",
      alt: "img alt text"
    },
    content: {
      h3: "Our Authors",
      p: "We're a team of baristas, coffee roasters, entrepreneurs, and coffee aficionados who want to share our expert advice, product recommendations, and an all-around passion for coffee with folks like you.",
    }
  };


  const bannerInfo2 = {
    layout: "text-float",
    image: {
      src: "https://via.placeholder.com/1200x700.png/09f/fff",
      alt: "img alt text"
    },
    content: {
      h3: "Dedicated to Coffee",
      p: "Some kind of mission statement here? Maybe. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    }
  };

  return (
    <Layout>
      <EngagementBanner data={bannerInfo} />
      <section>
        <header><h2>Meet the Team</h2></header>
        <AuthorsList />
      </section>
      <EngagementBanner data={bannerInfo2} />
      <ExploreCategories />
      <NewsletterSignup />
    </Layout>
  );
}
